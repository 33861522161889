import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Icon, IconWithText } from "@4cplatform/elements/Atoms"
import { P, H3, SmallText } from "@4cplatform/elements/Typography"
import { Checkbox } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { Modal, Skeleton } from "@4cplatform/elements/Molecules"

// Helpers
import { breakpoints, colours, formatLabelForTestID } from "@4cplatform/elements/Helpers"
import { JourneyContext } from "../../../../../../journey.context"
import { QuoteComparisonContext } from "./quoteComparison.context"
import {
  getHospitalListHelperText,
  getOutpatientHelperText,
  getExcessHelperText,
  getUnderwritingHelperText,
  getPremiumHelperText
} from "./quoteComparison.helpers"

// Components
import {
  QuoteWrapper,
  QuoteHeader,
  QuoteProviderInfo,
  QuoteSection,
  QuoteSectionDivider,
  QuoteFooter,
  QuotePremiumInfo,
  QuotePremiumHeader,
  QuoteErrorWrapper,
  QuoteErrorText,
  QuoteSelect,
  ProviderInfoWrapper,
  IndicatorWrapper
} from "./quoteComparison.styles"
import ProviderLogo from "./quoteComparison.quote.logo"
import ProviderInfoModal from "./quoteComparison.quote.providerModal"

// eslint-disable-next-line arrow-body-style
const Quote = ({ data, isFirst, isLast }) => {
  const environment = process.env.NODE_ENV

  const t = useTranslations()
  const { data: journeyData, formik } = useContext(JourneyContext)
  const {
    expectedQuotes,
    onSelectQuote,
    onDeselectQuote,
    isDisabled,
    providerInfoModal,
    setProviderInfoModal
  } = useContext(QuoteComparisonContext)

  const [axaPhHolTwoMonthsFreePromoModalOpen, setAxaPhHolTwoMonthsFreePromoModalOpen] =
    useState(false)
  const width = document.body.clientWidth >= breakpoints.medium ? "800px" : "100%"
  const hasError = get(data, "success") !== undefined && !get(data, "success")
  const hasArrived =
    hasError !== undefined && expectedQuotes.includes(get(data, "quote_request_id"))

  const isSelected =
    hasArrived && get(formik, "values.selected_quotes", []).includes(get(data, "data.quote_id"))
  const providerName = get(data, "data.provider.name", "Provider name")
  const productName = get(data, "data.product", "Product name")

  const getSelectLabel = () => {
    if (hasError) return "Unavailable for selection"
    return isSelected ? "You have selected this quote" : "Select for comparison"
  }

  const flags = get(data, "flags", {})
  const isSwitchSave = get(data, "data.quote_type", null) === "switch_save"
  let hospitalList = get(
    data,
    "data.options['Hospital List']",
    get(data, "data.options['Hospital Option']", get(data, "data.options['Hospital Network']", "-"))
  )

  if (providerName.includes("AXA")) {
    hospitalList = "Fixed List"
    if (
      productName.includes("Personal Health") &&
      get(data, "data.options['Guided Option']") === "Yes"
    ) {
      hospitalList = "Guided"
    }
  }
  const handleAxaPhHolTwoMonthsFreePromo = () => {
    setAxaPhHolTwoMonthsFreePromoModalOpen(true)
  }

  const handleAxaPhHolTwoMonthsFreePromoClose = () => {
    setAxaPhHolTwoMonthsFreePromoModalOpen(false)
  }

  return (
    <>
      <QuoteWrapper hasError={hasError} isSelected={isSelected} isFirst={isFirst} isLast={isLast}>
        <QuoteHeader style={{ position: "relative" }}>
          {isSwitchSave && (
            <div
              className="tag"
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                background: "#197DA4",
                padding: "5px",
                "border-radius": "5px",
                color: "#fff"
              }}
            >
              Switch & save up to 10%
            </div>
          )}
          {get(flags, "BUPA_HEALTH_LIFESTYLE_DISCOUNT", false) && (
            <div
              className="tag"
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                background: "#197DA4",
                padding: "5px",
                "border-radius": "5px",
                color: "#fff"
              }}
            >
              Health and Lifestyle Discount Applied
            </div>
          )}
          {get(flags, "VITALITY_MORI_PLUS", false) && (
            <div
              className="tag"
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                background: "#197DA4",
                padding: "5px",
                "border-radius": "5px",
                color: "#fff"
              }}
            >
              Mori+ Discount Applied
            </div>
          )}
          {get(flags, "AXA_PH_HOL_TWO_MONTHS_FREE_PROMO", false) && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <button
                type="button"
                className="tag"
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  background: "#197DA4",
                  padding: "5px",
                  "border-radius": "5px",
                  color: "#fff",
                  cursor: "pointer",
                  border: 0
                }}
                onClick={handleAxaPhHolTwoMonthsFreePromo}
              >
                2 Months Free Promotion
              </button>
              {axaPhHolTwoMonthsFreePromoModalOpen && (
                <Modal
                  width={width}
                  title="Eligibility for the Promotion"
                  onClose={handleAxaPhHolTwoMonthsFreePromoClose}
                >
                  <P>
                    <strong>Customer Requirements:</strong>
                  </P>
                  <ul>
                    <li>Must be over 18 years old.</li>
                    <li>
                      Must receive a quote for an Eligible Plan from a Broker during the Offer
                      Period.
                    </li>
                    <li>
                      Must purchase an Eligible Plan from a Broker during the Offer Period with
                      coverage starting within the same period.
                    </li>
                    <li>
                      Must be a new Customer, meaning they cannot have been a member of any plan
                      underwritten or administered by AXA PPP healthcare Limited or AXA Health
                      Services Limited in the last three months.
                    </li>
                  </ul>
                  <br />
                  <P>
                    <strong>Offer Period:</strong>
                  </P>
                  <P>Opening: 00:00 GMT on 4 February 2025</P>
                  <P>Closing: 23:59 GMT on 31 March 2025</P>
                  <br />
                  <P>
                    <strong>Claiming the Promotion</strong>
                  </P>
                  <P>
                    <strong>Free Cover:</strong>
                  </P>
                  <P>
                    Customers fulfilling the conditions will receive 2 months of free insurance
                    cover in the first year.
                  </P>
                  <P>
                    <strong>Payment Options:</strong>
                  </P>
                  <P>
                    Annual Payment: Charged for 10 months of cover plus a 5% discount for paying
                    annually.
                  </P>
                  <P>Monthly Instalments: Months 4 and 5 will be free of charge.</P>
                </Modal>
              )}
            </>
          )}
          <ProviderInfoWrapper>
            <Icon
              icon="help-circle-outline"
              isLoading={!hasArrived}
              colour={colours.blue}
              onClick={() =>
                setProviderInfoModal({
                  open: true,
                  data: { ...get(data, "data.provider"), product: get(data, "data.product") }
                })
              }
              role="button"
              transition="none"
              margin={!hasArrived ? "0 1rem 0 0" : "0"}
              data-testid={`${formatLabelForTestID(providerName)}-helper_text-icon`}
            />
          </ProviderInfoWrapper>
          <ProviderLogo
            src={get(data, "data.provider.logo", null)}
            alt={providerName}
            isLoading={!hasArrived}
            loadingMargin="0 1rem"
          />
          <QuoteProviderInfo>
            <P margin="0" isLoading={!hasArrived} loadingLines={1} loadingWidth="15rem">
              <strong>{providerName}</strong>
            </P>
            <SmallText margin="0" isLoading={!hasArrived} loadingLines={1} loadingWidth="15rem">
              {t(productName, "-")}
            </SmallText>
          </QuoteProviderInfo>
        </QuoteHeader>
        {!hasError && (
          <>
            <QuoteSection>
              <IndicatorWrapper>
                {getUnderwritingHelperText(flags).map(helper => (
                  <P margin="0" {...helper} />
                ))}
                <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                  Underwriting
                </P>
              </IndicatorWrapper>
              <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                {!get(flags, "VITALITY_MORI_PLUS", false) && (
                  <strong>
                    {t(get(data, "data.underwriting_style"))}{" "}
                    {t(get(data, "data.underwriting_type"))}
                  </strong>
                )}
                {get(flags, "VITALITY_MORI_PLUS", false) && (
                  <strong>{t(get(data, "data.underwriting_style"))} Moratorium+</strong>
                )}
              </P>
            </QuoteSection>
            <QuoteSectionDivider />
            <QuoteSection>
              {isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    {getHospitalListHelperText(flags).map(helper => (
                      <P margin="0" {...helper} />
                    ))}
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Hospital list
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>{t(get(data, "data.current_policy_options.HospitalList"))}</strong>
                  </P>
                </>
              )}
              {!isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    {getHospitalListHelperText(flags).map(helper => (
                      <P margin="0" {...helper} />
                    ))}
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Hospital list
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>{hospitalList}</strong>
                  </P>
                </>
              )}
            </QuoteSection>
            <QuoteSectionDivider />
            <QuoteSection>
              {isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Outpatient
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>{t(get(data, "data.current_policy_options.Outpatient"))}</strong>
                  </P>
                </>
              )}
              {!isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    {getOutpatientHelperText(flags).map(helper => (
                      <P margin="0" {...helper} />
                    ))}
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Outpatient
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>
                      {t(
                        get(
                          data,
                          "data.options['Outpatient Limit']",
                          get(
                            data,
                            "data.options['Out-patient Cover']",
                            get(
                              data,
                              "data.options['Out-Patient Cover']",
                              get(data, "data.options['Out-Patient']")
                            )
                          )
                        )
                      )}
                    </strong>
                  </P>
                </>
              )}
            </QuoteSection>
            <QuoteSectionDivider />
            <QuoteSection>
              {isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Excess
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>
                      {get(data, "data.current_policy_options.Excess", "").replace(
                        /\b[a-z]/g,
                        function (excess) {
                          return excess.toUpperCase()
                        }
                      )}
                    </strong>
                  </P>
                </>
              )}
              {!isSwitchSave && (
                <>
                  <IndicatorWrapper>
                    {getExcessHelperText(flags).map(helper => (
                      <P margin="0" {...helper} />
                    ))}
                    <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                      Excess
                    </P>
                  </IndicatorWrapper>
                  <P margin="0" isLoading={!hasArrived} loadingLines={1}>
                    <strong>
                      {get(data, "data.options.Excess").replace(/\b[a-z]/g, function (excess) {
                        return excess.toUpperCase()
                      })}
                    </strong>
                  </P>
                </>
              )}
            </QuoteSection>
            <QuoteFooter>
              <QuotePremiumHeader>
                <IndicatorWrapper>
                  {getPremiumHelperText(flags, environment).map(helper => (
                    <P margin="0" {...helper} />
                  ))}
                  <H3
                    margin={hasArrived ? "0" : "0 0 1rem 0"}
                    isLoading={!hasArrived}
                    loadingLines={1}
                    loadingWidth="12rem"
                  >
                    Premium
                  </H3>
                </IndicatorWrapper>
                {!get(journeyData, "page.conditionals.skip_affordable_budget", false) && (
                  <IconWithText
                    icon={!get(flags, "QUOTE_OVER_BUDGET", false) ? "check-circle" : "close-circle"}
                    content={!get(flags, "QUOTE_OVER_BUDGET", false) ? "In budget" : "Over budget"}
                    iconColour={
                      !get(flags, "QUOTE_OVER_BUDGET", false) ? colours.green : colours.red
                    }
                    fontColour={
                      !get(flags, "QUOTE_OVER_BUDGET", false) ? colours.green : colours.red
                    }
                    margin={hasArrived ? "0" : "0 0 1rem 0"}
                    isLoading={!hasArrived}
                    loadingWidth="8rem"
                  />
                )}
              </QuotePremiumHeader>
              <QuotePremiumInfo>
                {hasArrived && (
                  <>
                    <P margin="0">
                      Monthly <br />{" "}
                      <strong>£{parseFloat(get(data, "data.monthly_premium")).toFixed(2)}</strong>
                    </P>
                    <P margin="0">
                      Annually <br />{" "}
                      <strong>£{parseFloat(get(data, "data.annual_premium")).toFixed(2)}</strong>
                    </P>
                  </>
                )}
                {!hasArrived && <Skeleton />}
              </QuotePremiumInfo>
            </QuoteFooter>
          </>
        )}
        {hasError && (
          <QuoteErrorWrapper>
            <Icon icon="alert" colour={colours.red} transition="none" size="3rem" />
            <P align="center" colour={get(colours, "red", "red")}>
              <strong>{t(`QUOTE_${get(data, "data.message")}`)}</strong>
            </P>
            <QuoteErrorText>
              {get(data, "data.details") !== "TIMED_OUT"
                ? get(data, "data.details")
                : "The quote request has timed out"}
            </QuoteErrorText>
          </QuoteErrorWrapper>
        )}
        <QuoteSectionDivider />
        <QuoteSelect isSelected={isSelected} hasError={hasError}>
          {!hasArrived && <Skeleton />}
          {hasArrived && (
            <Checkbox
              name={`select_quote_${get(data, "quote_request_id")}`}
              value={isSelected}
              onChange={() => {
                if (isSelected) {
                  onDeselectQuote(data)
                } else {
                  onSelectQuote(data)
                }
              }}
              label={getSelectLabel()}
              margin="0"
              appearance={isSelected ? "light" : "dark"}
              isDisabled={isDisabled || hasError}
            />
          )}
        </QuoteSelect>
      </QuoteWrapper>
      {get(providerInfoModal, "open", false) &&
        get(providerInfoModal, "data.name") === providerName &&
        get(providerInfoModal, "data.product") === productName && <ProviderInfoModal />}
    </>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired
}

export default Quote
